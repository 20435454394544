.filePreviewContainer {
  container-type: inline-size;

  .fileName {
    max-width: 35ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* 23.75rem = 380px assuming 1rem = 16px */
  @container (max-width: 23.75rem) {
    .fileName {
      max-width: 8ch;
    }
  }
}

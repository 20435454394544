.date-picker {
  [class~="DatePicker-Popper"] {
    @apply max-w-full;

    [class~="react-datepicker"] {
      @apply rounded-md border bg-popover text-popover-foreground shadow-md;

      [class~="react-datepicker__triangle"] {
        @apply hidden;
      }

      [class~="react-datepicker__navigation"] {
        @apply mt-2 h-7 w-7;
      }

      [class~="react-datepicker__navigation-icon"] {
        @apply before:border-r before:border-t before:border-foreground;
      }

      [class~="react-datepicker__navigation--previous"] {
        @apply left-0;
      }

      [class~="react-datepicker__navigation--next"] {
        @apply right-0;
      }

      [class~="react-datepicker__day-names"] {
        @apply px-2.5;
      }

      [class~="react-datepicker__day--today"] {
        @apply bg-accent font-normal;
      }

      [class~="react-datepicker__month"] {
        @apply m-0 bg-transparent p-4;
      }

      [class~="react-datepicker__header"] {
        @apply bg-transparent;

        [class~="react-datepicker__current-month"] {
          @apply hidden;
        }

        [class~="react-datepicker__header__dropdown"] {
          @apply mt-0.5;

          select {
            @apply border-none bg-transparent p-0 pr-[5px] text-base font-bold focus-visible:outline-none;
          }
        }
      }

      [class~="react-datepicker__day-name"] {
        @apply text-muted-foreground;
      }

      [class~="react-datepicker__day"] {
        @apply m-0 h-9 w-9 rounded-medium p-1.5 text-base text-popover-foreground;

        &:hover {
          @apply bg-accent text-accent-foreground;
        }
      }

      [class~="react-datepicker__day--keyboard-selected"] {
        @apply bg-primary text-primary-foreground;
      }

      [class~="react-datepicker__day--outside-month"] {
        @apply text-muted-foreground;
      }

      [class~="react-datepicker__day--selected"] {
        @apply bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground;
      }

      [class~="react-datepicker__input-time-container"] {
        @apply m-0 w-full items-center justify-start p-4 pt-0;

        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

        [class~="react-datepicker-time__input-container"] {
          @apply w-full;
        }

        [class~="react-datepicker-time__caption"] {
          @apply hidden;
        }

        [class~="react-datepicker-time__input"] {
          @apply m-0 block;
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
          @apply opacity-70 brightness-[91%] contrast-[86%] hue-rotate-180 invert-[31%] saturate-[1984%] sepia-[12%];
        }
      }
    }
  }

  [class~="react-datepicker__close-icon"] {
    @apply p-0 pr-3.5;
  }

  [class~="react-datepicker__close-icon"]:after {
    padding: 2px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 17px;
  }
}

.container {
  container-type: inline-size;

  [class~="uploadcare--widget__button"] {
    @apply h-10 cursor-pointer rounded-md border border-solid border-border bg-transparent px-4 py-2 text-sm text-inherit outline-0 transition-all duration-150 hover:bg-secondary hover:text-secondary-foreground;

    &:disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }

  [class~="uploadcare--widget__file-name"] {
    @apply ml-2 text-card-foreground;
  }

  /* 23.75rem = 380px assuming 1rem = 16px */
  @container (max-width: 23.75rem) {
    .placeholder {
      @apply hidden;
    }

    [class~="uploadcare--widget"] {
      @apply flex-auto;
    }

    [class~="uploadcare--widget__button"] {
      @apply w-full;
    }
  }
}

.container {
  /* See https://stackoverflow.com/a/68848159 */
  [class~="PhoneInput"] {
    @apply w-full;
  }

  [class~="PhoneInputCountry"] {
    margin: 0;
  }

  [class~="PhoneInputCountryIcon"] {
    @apply shadow-[0_2px_4px_rgba(0,0,0,0.12)];
  }

  input {
    all: unset;
    @apply w-full pl-2.5;
  }
}

.form {
  container-type: inline-size;
  width: 100%;
}

.form-grid {
  display: grid;
  /* Arbitrary numbers */
  grid-template-columns: minmax(96px, 104px) 1fr;
  gap: 0.75rem;

  /* 26.25rem = 420px. Arbitrary number. */
  @container (max-width: 26.25rem) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
